import React, { useState } from "react";
import { numberWithCommas } from "../utils/Utils";
import chroma from "chroma-js";

function RegionalImpactTable(props) {
  const [showMore, setShowMore] = useState(false);
  // TODO: refactor to aggregate
  const chromaScale = chroma
    .scale(["white", "008ae5"])
    .domain([props.data[props.data.length - 1].value, props.data[0].value]);

  const DisplayData = props.data.map((region) => {
    return (
      <>
        <tr
          style={{
            borderColor: "black",
            borderBottom: "3px solid rgb(250, 0, 212)",
          }}
          key={region.name}
        >
          <td style={{ whiteSpace: "nowrap", textAlign: "center" }}>
            {region.name}
          </td>
          <td
            style={{
              textAlign: "center",
              backgroundColor: chromaScale(region.value).hex(),
            }}
          >
            {numberWithCommas(region.value)}
          </td>
        </tr>
      </>
    );
  });

  return (
    <div style={{ padding: 20 }}>
      <h2>Total Regional Impact</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Region Name</th>
            <th>Impact(min)</th>
          </tr>
        </thead>
        <tbody>{showMore ? DisplayData : DisplayData.slice(0, 10)}</tbody>
        <button className="btn" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show less" : "Show more"}
        </button>
      </table>
    </div>
  );
}

export default RegionalImpactTable;
