import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { analytics } from "../index";
import { logEvent } from "firebase/analytics";
import { INCIDENT_BASE_URL } from "../constants/Constants";
import { mapStatusImpactToIcon } from "../utils/Utils";
import chroma from "chroma-js";

export const INCIDENT_UPTIME_CALLOUT_PERCENTAGE = 95.0;

function IncidentTable(props) {
  const [showMore, setShowMore] = useState(false);
  const chromaScale = chroma
    .scale(["white", "red"])
    .domain([props.data[props.data.length - 1].value, props.data[0].value]);

  const DisplayData = props.data.map((incidents) => {
    const url = INCIDENT_BASE_URL + incidents.uri;
    return (
      <tr
        style={{
          borderWidth: 5,
          borderColor: "black",
          background: `${
            incidents.end_string !== "ongoing" ? "#cecfcc" : "#d48a83"
          }`,
        }}
        key={incidents.id}
        onClick={() => {
          props.modalCallback(incidents);
        }}
      >
        <td>
          <a
            href={`${url}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              logEvent(analytics, `Click: ${incidents.service_name}`)
            }
          >
            {incidents.service_name}
          </a>
        </td>
        <td style={{ width: 500 }}>{incidents.external_desc}</td>
        <td>
          {new Date(incidents.begin).toLocaleDateString() +
            " " +
            new Date(incidents.begin).toLocaleTimeString()}
        </td>
        <td>{incidents.end_string}</td>
        <td
          style={{
            background: `${
              incidents.uptime_percentage >= INCIDENT_UPTIME_CALLOUT_PERCENTAGE
                ? "#cecfcc"
                : "#db485c"
            }`,
          }}
        >
          {incidents.duration + " min (" + incidents.uptime_percentage + ")"}
        </td>
        <td>{incidents.create_lag + " min"}</td>
        <td>{incidents.severity}</td>
        <td>{mapStatusImpactToIcon(incidents.status_impact)}</td>
        <td>
          {
            incidents.currently_affected_locations.concat(
              incidents.previously_affected_locations.filter(
                (item) =>
                  incidents.currently_affected_locations.indexOf(item) < 0
              )
            ).length
          }
        </td>
        <td
          onMouseOver={() => {
            console.log(
              "affectedProducts length: ",
              incidents.affected_products.size
            );
            props.setTooltipContent(
              incidents.affected_products.map(function (i) {
                return i["title"];
              })
              // .toString()
            );
          }}
          onMouseLeave={() => {
            props.setTooltipContent([]);
          }}
        >
          {incidents.affected_products.length}
        </td>
      </tr>
    );
  });

  return (
    <div data-tip data-for="incident-service">
      <table className="table table-striped">
        <thead>
          <tr>
            <th data-tip data-for="service_name">
              Service Name
            </th>
            <th data-tip data-for="ext_desc">
              Ext Desc
            </th>
            <th data-tip data-for="begin_time">
              Begin
            </th>
            <th data-tip data-for="end_time">
              End
            </th>
            <th data-tip data-for="duration">
              Duration
            </th>
            <th data-tip data-for="create_lag">
              Create Lag
            </th>
            <th>Severity</th>
            <th>Impact</th>
            <th data-tip data-for="num_regions">
              Num Regions
            </th>
            <th data-tip data-for="num_products">
              Num Products
            </th>
          </tr>
        </thead>
        <tbody>{showMore ? DisplayData : DisplayData.slice(0, 10)}</tbody>
        <button className="btn" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show less" : "Show more"}
        </button>
      </table>
      <ReactTooltip id="service_name" place="top" effect="solid">
        Name of the primary service impacted
      </ReactTooltip>
      <ReactTooltip id="ext_desc" place="top" effect="solid">
        Description of impact
      </ReactTooltip>
      <ReactTooltip id="begin_time" place="top" effect="solid">
        Time the affected services began to see impact
      </ReactTooltip>
      <ReactTooltip id="end_time" place="top" effect="solid">
        Time the affected services began to see impact
      </ReactTooltip>
      <ReactTooltip id="duration" place="top" effect="solid">
        Duration of the impact
      </ReactTooltip>
      <ReactTooltip id="create_lag" place="top" effect="solid">
        Time difference in incident creation time and begin of impact
      </ReactTooltip>
      <ReactTooltip id="num_regions" place="top" effect="solid">
        The previous and currently impacted regions(Total Impact)
      </ReactTooltip>
      <ReactTooltip id="num_products" place="top" effect="solid">
        The Number of GCP Products impacted by the incident)
      </ReactTooltip>
    </div>
  );
}

export default IncidentTable;
