import { isAfter, subDays } from "date-fns";
const GCP_REGIONS = require("../config/gcp-regions.json");

export function severityToColor(severity) {
  switch (severity) {
    case "low":
      return "yellow";
    case "medium":
      return "orange";
    case "high":
      return "red";
    default:
      return "gray";
  }
}
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function dateToInvoiceMonth(date) {
  return String(String(date.getFullYear()) + String(date.getMonth()));
}
export function mapToganttChart(data) {
  const ganttData = data.map((incident) => ({
    id: incident.number,
    name: incident.service_name,
    start: new Date(incident.begin),
    end: incident.end !== undefined ? new Date(incident.end) : new Date(),
    color: severityToColor(incident.severity),
  }));

  return ganttData;
}

export function sliceGantt(incidents, viewPortWidth) {
  const incidentRowLabelWidth = 465;
  const columnWidthPerDay = 60;
  const numberOfColumnsToDisplay = Math.round(
    (viewPortWidth - incidentRowLabelWidth) / columnWidthPerDay
  );

  const startDate = subDays(new Date(), numberOfColumnsToDisplay);

  let ganttIncidents = [];

  incidents.forEach((incident) => {
    if (isAfter(new Date(incident.begin), startDate)) {
      ganttIncidents.push(incident);
    }
  });

  return ganttIncidents.length === 0 ? [incidents[0]] : ganttIncidents;
}

export function mapStatusImpactToIcon(status_impact) {
  switch (status_impact) {
    case "SERVICE_INFORMATION":
      return "ℹ️";
    case "SERVICE_DISRUPTION":
      return "⚠️";
    case "SERVICE_OUTAGE":
      return "⛔️";
    default:
      return status_impact;
  }
}

export function regionToMap(region) {
  const regionResp = GCP_REGIONS.find((r) => r.id === region);
  console.log("region Resp: ", regionResp);
  return regionResp;
}
