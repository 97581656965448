import { formatDistance } from "date-fns";
import Modal from "react-modal";
import DynamicTable from "./DynamicTable";
import { INCIDENT_BASE_URL } from "../constants/Constants";
import MapChart from "./MapChart";
import { regionToMap } from "../utils/Utils";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

export function IncidentModal(props) {
  // console.log("incidentModal ", props);
  const inc = props.data;

  const previouslyAffectedLocations =
    inc && inc.previously_affected_locations.length > 0
      ? inc.previously_affected_locations.map((i) => {
          return {
            ...i,
            ...regionToMap(i.id),
            value: 1,
          };
        })
      : [];

  const currentlyAffectedLocations =
    inc && inc.currently_affected_locations.length > 0
      ? inc.currently_affected_locations.map((i) => {
          return {
            ...i,
            ...regionToMap(i.id),
            value: 1,
          };
        })
      : [];

  // console.log("mapInc: ", previouslyAffectedLocations);

  return (
    <div onClick={props.modalClose}>
      <Modal isOpen={props.modalisOpen}>
        <button onClick={props.modalClose}>x</button>
        {inc ? (
          <div>
            <div>
              <h1>Incident Name: {inc.name}</h1>
            </div>
            <h2>Description: {inc.external_desc}</h2>
            <p>Status: {inc.status_impact}</p>
            <p>
              Severity: <mark>{inc.severity}</mark>
            </p>
            <p>
              Begin:{" "}
              {new Date(inc.begin).toLocaleDateString() +
                " " +
                new Date(inc.begin).toLocaleTimeString()}
            </p>
            <p>
              End:{" "}
              {new Date(inc.end).toLocaleDateString() +
                " " +
                new Date(inc.end).toLocaleTimeString()}
            </p>
            <p>
              Duration: {formatDistance(new Date(inc.begin), new Date(inc.end))}
            </p>
            <p>Monthly Impact: {inc.uptime_percentage}</p>

            {/* Most Recent update */}

            {/* Updates Table */}
            <DynamicTable
              tbodyData={inc.updates.map(
                ({ affected_products, affected_locations, ...keepProps }) =>
                  keepProps
              )}
            />

            {/* Small region map */}
            {/* previous vs current impact */}
            {previouslyAffectedLocations.length > 0 ? (
              <div
                style={{
                  width: 400,
                  //  borderColor: "black", border: "1px solid "
                }}
              >
                <h2>Previously Affected Locations</h2>
                <MapChart mapSize="SMALL" data={previouslyAffectedLocations} />
              </div>
            ) : (
              <></>
            )}
            {currentlyAffectedLocations.length > 0 ? (
              <div
                style={{
                  width: 400,
                }}
              >
                <h2>Current Affected Locations</h2>
                <MapChart mapSize="SMALL" data={currentlyAffectedLocations} />
              </div>
            ) : (
              <></>
            )}

            {/* List of Services impacted */}
            <DynamicTable
              title="Services Impacted"
              tbodyData={inc.affected_products}
            />
            <iframe
              style={{ width: "100%", height: 500 }}
              src={INCIDENT_BASE_URL + inc.uri}
            />
            <h2>Raw Incident Data</h2>
            <CodeBlock
              text={JSON.stringify(inc, null, 2)}
              language={"javascript"}
              showLineNumbers={true}
              theme={dracula}
            />
          </div>
        ) : (
          <p>no data</p>
        )}
        <p></p>
      </Modal>
    </div>
  );
}
