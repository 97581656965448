import chroma from "chroma-js";
import { center_column } from "../styles";

export default function DynamicTable({ title, tbodyData }) {
  console.log("dynamic table data: ", tbodyData);
  const chromaScale = chroma.scale(["white", "DE5246"]).domain([0, 200]);

  const getHeadings = Object.keys(tbodyData[0]);

  return (
    <div>
      <div style={center_column}>{title ? <h2>{title}</h2> : <></>}</div>
      <div style={center_column}>
        <table>
          <thead>
            <tr>
              {getHeadings.map((heading) => {
                return <th key={heading}>{heading}</th>;
              })}
            </tr>
          </thead>

          <tbody>
            {tbodyData.map((row, index) => {
              return (
                <tr key={index}>
                  {getHeadings.map((key, index) => {
                    return (
                      <td
                        style={{
                          backgroundColor: chromaScale(
                            Array.isArray(row[key]) ? row[key].length : row[key]
                          ),
                        }}
                        key={row[key]}
                      >
                        {Array.isArray(row[key]) ? row[key].length : row[key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
