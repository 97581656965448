export const SLAs = () => {
  const slaMap = new Map();

  slaMap.set("Cloud Spanner", {
    termsLink: "https://cloud.google.com/spanner/sla",
  });
  slaMap.set("Google Cloud Functions", {
    termsLink: "https://cloud.google.com/functions/sla",
  });
  slaMap.set("Google Kubernetes Engine", {
    termsLink: "https://cloud.google.com/kubernetes-engine/sla",
  });
  slaMap.set("Compute Engine", {
    termsLink: "https://cloud.google.com/compute/sla",
  });
  slaMap.set("Google Compute Engine", {
    termsLink: "https://cloud.google.com/compute/sla",
  });
  slaMap.set("Google Cloud Dataflow", {
    termsLink: "https://cloud.google.com/dataflow/sla",
  });
  slaMap.set("Cloud Security Command Center", {
    termsLink: "https://cloud.google.com/security-command-center/sla",
  });
  slaMap.set("Operations", {
    termsLink: "https://cloud.google.com/operations/sla",
  });
  slaMap.set("Cloud Monitoring", {
    termsLink: "https://cloud.google.com/operations/sla",
  });
  slaMap.set("Cloud Logging", {
    termsLink: "https://cloud.google.com/operations/sla",
  });
  slaMap.set("Google Cloud Storage", {
    termsLink: "https://cloud.google.com/storage/sla",
  });
  slaMap.set("Dataplex", {
    termsLink: "https://cloud.google.com/dataplex/sla",
  });
  slaMap.set("Google Cloud Pub/Sub", {
    termsLink: "https://cloud.google.com/pubsub/sla",
  });
  slaMap.set("Google Cloud Bigtable", {
    termsLink: "https://cloud.google.com/bigtable/sla",
  });
  slaMap.set("Google App Engine", {
    termsLink: "https://cloud.google.com/appengine/sla",
  });
  slaMap.set("Cloud Run", {
    termsLink: "https://cloud.google.com/run/sla",
  });
  slaMap.set("Dialogflow CX", {
    termsLink: "https://cloud.google.com/dialogflow/sla",
  });
  slaMap.set("Dataproc", {
    termsLink: "https://cloud.google.com/dataproc/sla",
  });
  slaMap.set("Firestore", {
    termsLink: "https://cloud.google.com/firestore/sla",
  });
  slaMap.set("Cloud Armor", {
    termsLink: "https://cloud.google.com/armor/sla",
  });
  slaMap.set("Google BigQuery", {
    termsLink: "https://cloud.google.com/bigquery/sla",
  });
  slaMap.set("Google Cloud SQL", {
    termsLink: "https://cloud.google.com/sql/sla",
  });
  slaMap.set("Cloud DNS", { termsLink: "https://cloud.google.com/dns/sla" });
  return slaMap;
};
