export const center_column = {
  display: "flex",
  flex: 1,
  justifyContent: "center",
};

export const row_center = {
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "center",
};

export const row_even = {
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
};
