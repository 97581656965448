import React, { useState } from "react";
import { analytics } from "../index";
import { logEvent } from "firebase/analytics";

export const SERVICE_CALLOUT_UPTIME_PERCENTAGE = 95;
function ServiceUptimeTable(props) {
  const [showMore, setShowMore] = useState(false);

  const DisplayData = props.data.map((service) => {
    // console.log("service: ", service);
    return (
      <tr
        style={{
          borderColor: "black",
          borderBottom: "3px solid rgb(250, 0, 212)",
        }}
        key={service.name}
      >
        <td>{service.name}</td>
        <td style={{ textAlign: "center" }}>{service.value}</td>
        <td style={{ textAlign: "center" }}>
          {service.slaTermsLink ? (
            <a
              href={`${service.slaTermsLink}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logEvent(analytics, `Click: ${service.name}`)}
            >
              {service.monthly_uptime} %
            </a>
          ) : (
            <a
              href={`https://cloud.google.com/terms/sla`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logEvent(analytics, `Click: ${service.name}`)}
            >
              {service.monthly_uptime} %
            </a>
          )}
        </td>
        {/* ✅❗️‼️♨️🆘⛔️🔴🟢🔻 */}
        <td style={{ textAlign: "center" }}>
          {service.monthly_uptime >= SERVICE_CALLOUT_UPTIME_PERCENTAGE
            ? "✅"
            : "🆘"}{" "}
        </td>
      </tr>
    );
  });

  return (
    <div style={{ padding: 20 }}>
      <h2>{props.title}</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Impact(min)</th>
            <th>Monthly Impact %</th>
            <th>Impact</th>
          </tr>
        </thead>
        <tbody>{showMore ? DisplayData : DisplayData.slice(0, 10)}</tbody>
        <button className="btn" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Show less" : "Show more"}
        </button>
      </table>
    </div>
  );
}

export default ServiceUptimeTable;
