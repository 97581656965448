import { center_column, row_center } from "../styles";

export function ReferenceLinks() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "space-evenly",
        padding: 100,
      }}
    >
      <a
        href="https://raw.githubusercontent.com/outages/gcp-outages/main/gcp_outages.json"
        target="_blank"
        rel="noopener noreferrer"
      >
        Github link
      </a>

      <a
        href="https://status.cloud.google.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        GCP Status Page
      </a>
      <a
        href=" https://status.cloud.google.com/summary"
        target="_blank"
        rel="noopener noreferrer"
      >
        GCP Status Summary Page
      </a>
      <a
        href="https://cloud.google.com/terms/sla"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google Cloud Terms SLAs
      </a>
    </div>
  );
}
