import "./App.css";
import IncidentTable from "./components/IncidentTable";

import {
  enrichIncidentSummaryData,
  groupByRegion,
  monthlyServiceImpact,
  groupBy,
  createAggregateStatisticsSummary,
} from "./utils/downtime-calc";
import Coffee from "./components/Coffee";
import { useEffect, useState } from "react";
import { analytics } from "./index";
import { logEvent } from "firebase/analytics";
import { dateToInvoiceMonth, mapToganttChart, sliceGantt } from "./utils/Utils";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import logo from "./assets/base_logo_transparent_background.png";
import { ReferenceLinks } from "./components/ReferenceLinks";
import { center_column, row_center, row_even } from "./styles";
import cloud_health_loading_gif from "./assets/cloud-health.gif";

import RegionalImpactTable from "./components/RegionalImpactTable";
import { subMonths } from "date-fns";
import ServiceUptimeTable from "./components/ServiceUptimeTable";
import MapChart from "./components/MapChart";
import ReactTooltip from "react-tooltip";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { IncidentModal } from "./components/IncidentModal";
import DynamicTable from "./components/DynamicTable";

function App() {
  let [gnantChartData, setGnantChartData] = useState([]);
  let [enrichedIncidentSummaryData, setEnrichedIncidentSummaryData] = useState(
    []
  );
  let [incidentUptimeRollupData, setIncidentUptimeRollupData] = useState([]);
  let [severityGroups, setSeverityGroups] = useState([]);

  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [incidentModalData, setIncidentModalData] = useState(null);
  const [mapDetailModal, setmapDetailModal] = useState("");
  const [servicesImpactedModal, setservicesImpactedModal] = useState([]);
  const [earliestIncidentDate, setEarliestIncidentDate] = useState(null);
  const [aggregateStatistics, setAggregateStatistics] = useState(null);
  const { height, width } = useWindowDimensions();

  const PREVIOUS_INCIDENTS_TO_TABLE = 10;
  const SERVICES_TO_SHOW = 10;
  const LAST_MONTH_DATE = subMonths(new Date(), 1);
  const THIS_MONTH_DATE = new Date();
  const setModalIsOpenToTrue = () => {
    setDetailModalIsOpen(true);
  };

  const setModalIsOpenToFalse = () => {
    setDetailModalIsOpen(false);
  };

  useEffect(() => {
    document.title = "Cloud-Health.io";
    console.log("useEffect:", new Date().toISOString());
    async function fetchData() {
      logEvent(analytics, "fetchData");
      try {
        const response = await fetch(
          `https://status.cloud.google.com/incidents.json`
        );

        const data = await response.json();

        setGnantChartData(mapToganttChart(data));
        // console.log("data: ", data);
        const localEnrichedData = enrichIncidentSummaryData(data);
        setEnrichedIncidentSummaryData(localEnrichedData);
        setAggregateStatistics(
          createAggregateStatisticsSummary(localEnrichedData)
        );

        setIncidentUptimeRollupData(
          monthlyServiceImpact(
            dateToInvoiceMonth(THIS_MONTH_DATE),
            enrichedIncidentSummaryData
          )
        );
      } catch (error) {
        console.error("Error: calling incident api: ", error);
      }
    }
    fetchData().then(() => {
      console.log("state: ", {
        gnantChartData,
        incidentUptimeRollupData,
        enrichedIncidentSummaryData,
      });
      console.log("end: useEffect", new Date().toISOString());
    });
  }, []);

  useEffect(() => {
    console.log("state: ", {
      gnantChartData,
      incidentUptimeRollupData,
      enrichedIncidentSummaryData,
      severityGroups,
      aggregateStatistics,
    });
  }, [
    gnantChartData,
    incidentUptimeRollupData,
    enrichedIncidentSummaryData,
    severityGroups,
    aggregateStatistics,
  ]);
  useEffect(() => {
    setSeverityGroups(groupBy(enrichedIncidentSummaryData, "severity"));
  }, []);

  // useEffect(() => {
  //   setAggregateStatistics(
  //     createAggregateStatisticsSummary(enrichedIncidentSummaryData)
  //   );
  // }, []);
  // setAggregateStatistics(
  //   createAggregateStatisticsSummary(enrichedIncidentSummaryData)
  // );
  let regionalImpact = groupByRegion(enrichedIncidentSummaryData);
  let thisMonthServiceImpactTable;
  const thisMonth = monthlyServiceImpact(
    dateToInvoiceMonth(THIS_MONTH_DATE),
    enrichedIncidentSummaryData
  );

  if (thisMonth.length > 0) {
    thisMonthServiceImpactTable = (
      <ServiceUptimeTable title="This Month" data={thisMonth} />
    );
  } else {
    thisMonthServiceImpactTable = <h2>No Incidents yet this month 🥹</h2>;
  }

  function openIncidentModal(data) {
    setIncidentModalData(data);
    setModalIsOpenToTrue();
  }
  return enrichedIncidentSummaryData.length > 0 && regionalImpact.length > 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {/* <button onClick={setModalIsOpenToTrue}>Click to Open Modal</button> */}
      <IncidentModal
        data={incidentModalData}
        modalisOpen={detailModalIsOpen}
        modalClose={setModalIsOpenToFalse}
      />
      <div style={row_even}>
        <img height={200} width={300} src={logo} alt="Logo" />
        <Coffee />
      </div>
      <div style={center_column}></div>
      <div style={center_column}>
        <h1>Google Cloud Incident History</h1>
      </div>
      <div style={center_column}>{/* <Coffee /> */}</div>

      <div style={center_column}>
        <h1>Impact Summaries</h1>
      </div>
      <div style={center_column}> </div>
      <div style={row_center}>
        <ServiceUptimeTable
          title="Last Month"
          data={monthlyServiceImpact(
            dateToInvoiceMonth(LAST_MONTH_DATE),
            enrichedIncidentSummaryData
          )}
        />
        {thisMonthServiceImpactTable}
      </div>
      <div style={center_column}>
        <h1>{`Last ` + PREVIOUS_INCIDENTS_TO_TABLE + ` Incidents`}</h1>
      </div>
      <div
        style={{
          padding: 10,
          ...center_column,
        }}
      >
        <IncidentTable
          modalCallback={openIncidentModal}
          data={enrichedIncidentSummaryData}
          setTooltipContent={setservicesImpactedModal}
        />

        {servicesImpactedModal.length > 0 ? (
          <ReactTooltip id="incident-service">
            <div>
              {servicesImpactedModal.map((service) => {
                return <p>{service}</p>;
              })}
            </div>
          </ReactTooltip>
        ) : (
          <></>
        )}
      </div>

      <div>
        {" "}
        {aggregateStatistics !== null ? (
          <DynamicTable
            title={`Incident Roll up Stats as of ${aggregateStatistics.earliestReportedIncidentIncludedInResults.date}`}
            tbodyData={[
              aggregateStatistics.globalIncidents,
              aggregateStatistics.lowImpactIncidents,
              aggregateStatistics.mediumImpactIncidents,
              aggregateStatistics.highImpactIncidents,
              aggregateStatistics.multipleProductsImpactedIncident,
            ]}
          />
        ) : (
          <></>
        )}
      </div>
      <div style={center_column}>
        <h1>Incident History Gantt</h1>
      </div>
      <div className="time-line-container" style={{ padding: 10 }}>
        {" "}
        <Gantt
          preStepsCount={0}
          tasks={sliceGantt(enrichedIncidentSummaryData, width)}
        />
      </div>
      <div style={center_column}>
        <h1>
          Historical Impact as of{" "}
          {
            enrichedIncidentSummaryData[enrichedIncidentSummaryData.length - 1]
              .date
          }
        </h1>
        {/* <h2>Regional Impact</h2> */}
      </div>
      <div style={center_column}>
        <h2>Regional Impact</h2>
      </div>
      <div style={center_column}>
        <RegionalImpactTable data={regionalImpact} />
        <MapChart setTooltipContent={setmapDetailModal} data={regionalImpact} />
        {mapDetailModal.length > 0 ? (
          <ReactTooltip id="map">
            {/* TODO: Make more detailed component w/ info */}
            <div style={{ backgroundColor: "white", color: "black" }}>
              <h2>{mapDetailModal.split(":")[0]}</h2>
              <p>{mapDetailModal.split(":")[1]}</p>
              <p>Impact in min: {mapDetailModal.split(":")[2]}</p>
            </div>
          </ReactTooltip>
        ) : (
          <></>
        )}
      </div>

      <ReferenceLinks />
    </div>
  ) : (
    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
      <img src={cloud_health_loading_gif} alt="loading" />
    </div>
  );
}

export default App;
