import React, { useEffect, useState, useMemo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { csv, json } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import ReactTooltip from "react-tooltip";
import { numberWithCommas } from "../utils/Utils";
import chroma from "chroma-js";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-continents.json";

const MapChart = (props) => {
  const MAP_SIZE = props.mapSize;
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);

  const chromaScale = chroma
    .scale(["white", "red"])
    .domain([props.data[props.data.length - 1].value, props.data[0].value]);

  useEffect(() => {
    console.log(props);

    const sortedCities = sortBy(props.data, (o) => -o.value);
    setMaxValue(sortedCities[0].value);
    setData(sortedCities);
    console.log("mapData: ", data);
  }, []);

  const popScale = useMemo(
    () => scaleLinear().domain([0, maxValue]).range([0, 10]),
    [maxValue]
  );

  return (
    <ComposableMap
      height={400}
      // width={400}
      fle
      data-tip
      data-for="map"
      projectionConfig={{
        rotate: [-10, 0, 0],
        // scale: MAP_SIZE === "SMALL" ? 20 : 100,
      }}
    >
      <Geographies geography="/features.json">
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} fill="#DDD" />
          ))
        }
      </Geographies>
      {data.map(({ location, id, city_code, lng, lat, value }) => {
        return (
          <Marker
            key={city_code}
            coordinates={[lng, lat]}
            onMouseEnter={() => {
              props.setTooltipContent(
                `${id}: ${location}: ${numberWithCommas(value)}`
              );
            }}
            onMouseLeave={() => {
              props.setTooltipContent("");
            }}
          >
            <circle fill="#F53" stroke="#FFF" r={popScale(value)} />
          </Marker>
        );
      })}
    </ComposableMap>
  );
};

export default MapChart;
