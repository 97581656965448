import { INCIDENT_BASE_URL } from "../constants/Constants";
import { SLAs } from "../constants/Sla";
import { dateToInvoiceMonth, severityToColor } from "./Utils";
import { isAfter, subDays } from "date-fns";
const today = new Date();
const GCP_REGIONS = require("../config/gcp-regions.json");

// TODO: Replace
function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

function parseInvoiceMonth(invoiceMonth) {
  const year = Number(invoiceMonth.substring(0, 4));
  const month = Number(invoiceMonth.substring(4, 6)) + 1;
  return { year, month };
}

// TODO: Replace
const downTimePerMonthPercentage = (minutesImpacted, invoiceMonth) => {
  invoiceMonth = invoiceMonth
    ? invoiceMonth
    : String(new Date().getFullYear() + String(new Date().getMonth()));
  const { year, month } = parseInvoiceMonth(String(invoiceMonth));
  const daysInMonth = getDaysInMonth(year, month);
  let minutes_per_month = 60 * 24 * daysInMonth;
  return (100 - (minutesImpacted / minutes_per_month) * 100).toFixed(3);
};

function groupByService(impactPerService) {
  const map = new Map();

  impactPerService.forEach((serviceDateImpact) => {
    if (map.has(serviceDateImpact.service)) {
      const iteratableInstance = map.get(serviceDateImpact.service);
      // console.log("service to add: ", iteratableInstance);
      map.set(
        serviceDateImpact.service,
        iteratableInstance + serviceDateImpact.impact
      );
    } else {
      map.set(serviceDateImpact.service, serviceDateImpact.impact);
    }
  });
  const response = Array.from(map, ([name, value]) => ({ name, value }));

  return response;
}

export function groupByRegion(input) {
  // console.log("testingGroupBy: ", groupBy(input, "status_impact"));

  const map = new Map();
  input.forEach((inp) => {
    inp.previously_affected_locations.forEach((region) => {
      // console.log(`inp: ${JSON.stringify(inp)}, region: ${region}`);
      if (map.has(region.id)) {
        const iteratableInstance = map.get(region.id);
        // console.log("service to add: ", iteratableInstance);
        map.set(region.id, iteratableInstance + inp.duration);
      } else {
        map.set(region.id, inp.duration);
      }
    });
  });
  const mapSort2 = new Map([...map.entries()].sort((a, b) => b[1] - a[1]));
  const arr = Array.from(mapSort2, ([name, value]) => ({ name, value }));

  const newArr = arr.map((t1) => ({
    ...t1,
    ...GCP_REGIONS.find((t2) => t2.id === t1.name),
  }));
  return newArr;
}
export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function incidentsNDaysOld(incidents, today, days) {
  return incidents.filter((inc) =>
    isAfter(new Date(inc.date), subDays(today, days))
  );
}

function incidentsPerMonth(incidents) {
  let map = new Map();

  incidents.forEach((inc) => {
    const month = new Date(inc.begin).getMonth();
    // console.log(`inp: ${JSON.stringify(inp)}, region: ${region}`);
    if (map.has(month)) {
      const iteratableInstance = map.get(month);
      // console.log("service to add: ", iteratableInstance);
      map.set(month, iteratableInstance + 1);
    } else {
      map.set(month, 1);
    }
  });
  return map;
}

const NUMBER_OF_GCP_REGIONS = 34;

function lowImpactIncidentPredicate(i) {
  return i.severity === "low";
}
function mediumImpactIncidentPredicate(i) {
  return i.severity === "medium";
}
function highImpactIncidentPredicate(i) {
  return i.severity === "high";
}

function multipleProductsImpactedIncidentPredicate(i) {
  return i.affected_products.length > 1;
}
function globalIncidentPredicate(i) {
  return (
    i.currently_affected_locations.length > NUMBER_OF_GCP_REGIONS - 5 ||
    i.previously_affected_locations.length > NUMBER_OF_GCP_REGIONS - 5
  );
}
function calcIncidentsByCriteria(incidents, predicate, metric) {
  let globalIncidents = incidents.filter((i) => predicate(i));

  const timeAggregateResponse = {
    metric,
    incidentsToday: incidentsNDaysOld(globalIncidents, today, 1),
    sevenDayHistory: incidentsNDaysOld(globalIncidents, today, 7),
    thirtyDayIncidentAverage: incidentsNDaysOld(globalIncidents, today, 30),
    ninetyDayIncidentAverage: incidentsNDaysOld(globalIncidents, today, 90),
    threeHundredSixtyFiveDayIncidentAverage: incidentsNDaysOld(
      globalIncidents,
      today,
      365
    ),
    allTime: globalIncidents,
  };
  return timeAggregateResponse;
}

export function createAggregateStatisticsSummary(incidents) {
  console.log("createAggregateStatisticsSummary fn: ", incidents);
  // const today = new Date();

  const earliestReportedIncidentIncludedInResults =
    incidents[incidents.length - 1];

  let globalIncidents = calcIncidentsByCriteria(
    incidents,
    globalIncidentPredicate,
    "Global Incidents"
  );
  let lowImpactIncidents = calcIncidentsByCriteria(
    incidents,
    lowImpactIncidentPredicate,
    "low Impact Incidents"
  );
  let mediumImpactIncidents = calcIncidentsByCriteria(
    incidents,
    mediumImpactIncidentPredicate,
    "Medium Impact Incidents"
  );
  let highImpactIncidents = calcIncidentsByCriteria(
    incidents,
    highImpactIncidentPredicate,
    "High Impact Incidents"
  );
  let multipleProductsImpactedIncident = calcIncidentsByCriteria(
    incidents,
    multipleProductsImpactedIncidentPredicate,
    "Multiple Products Impacted"
  );

  const summary = {
    earliestReportedIncidentIncludedInResults,
    globalIncidents,
    lowImpactIncidents,
    mediumImpactIncidents,
    highImpactIncidents,
    multipleProductsImpactedIncident,
  };
  console.log("summary: ", summary);
  return summary;
}

export function monthlyServiceImpact(invoiceMonth, calculatedDowntime) {
  const service_uptime = [];
  // console.log("calculatedDowntime", invoiceMonth, calculatedDowntime);
  calculatedDowntime.forEach((incident) => {
    incident.affected_products.forEach((product) => {
      service_uptime.push({
        service: product.title,
        link: INCIDENT_BASE_URL + product.uri,
        impact: incident.duration,
        date: incident.date,
        date_year: new Date(incident.date).getFullYear(),
        date_year_month: String(
          String(new Date(incident.date).getFullYear()) +
            String(new Date(incident.date).getMonth())
        ),
      });
    });
  });

  const month_impact = groupByService(
    // service_uptime
    service_uptime.filter((service) => service.date_year_month === invoiceMonth)
  );

  const new_month = month_impact
    .map((service) => {
      const SLA = SLAs().get(service.name);
      const SLA_TERMS_LINK =
        SLA !== undefined && SLA.termsLink ? SLA.termsLink : "";
      return {
        ...service,
        incidentLink: service.link,
        monthly_uptime: downTimePerMonthPercentage(service.value, invoiceMonth),
        slaTermsLink: SLA_TERMS_LINK,
      };
    })
    .sort((a, b) => (a.monthly_uptime > b.monthly_uptime ? 1 : -1));
  return new_month;
}
export function newGanttMapping(rawIncidentData) {
  let enrichedDataResponse = [];

  rawIncidentData.forEach((incident) => {
    const duration =
      incident.end !== undefined
        ? Math.round(
            (new Date(incident.end).getTime() -
              new Date(incident.begin).getTime()) /
              (1000 * 60)
          )
        : Math.round(
            (new Date().getTime() - new Date(incident.begin).getTime()) /
              (1000 * 60)
          );
    console.log("affected_products:", incident.affected_products);

    if (incident.affected_products.length > 1) {
      let displayCount = 1;
      enrichedDataResponse.push({
        ...incident,
        id: incident.number,
        name: incident.service_name,
        type: "project",
        hideChildren: false,
        start: new Date(incident.begin),
        end: incident.end !== undefined ? new Date(incident.end) : new Date(),
        color: severityToColor(incident.severity),
        begin:
          new Date(incident.begin).toLocaleDateString() +
          " " +
          new Date(incident.begin).toLocaleTimeString(),
        end_string:
          incident.end !== undefined
            ? new Date(incident.end).toLocaleDateString() +
              " " +
              new Date(incident.end).toLocaleTimeString()
            : "ongoing",

        duration,
        create_lag: Math.round(
          (new Date(incident.created).getTime() -
            new Date(incident.begin).getTime()) /
            (1000 * 60)
        ),
        impacted_regions: incident.currently_affected_locations.concat(
          incident.previously_affected_locations.filter(
            (item) => incident.currently_affected_locations.indexOf(item) < 0
          )
        ),
        impacted_services: incident.affected_products.length,

        date_year: new Date(incident.begin).getFullYear(),
        date_month: new Date(incident.begin).getMonth(),
        date_day: new Date(incident.begin).getDay(),
        date_hour: new Date(incident.begin).getHours(),
        date_minutes: new Date(incident.begin).getMinutes(),
        date: new Date(incident.begin).toLocaleDateString("en-us"),

        uptime_percentage: downTimePerMonthPercentage(
          duration,
          String(
            String(new Date(incident.begin).getFullYear()) +
              String(new Date(incident.begin).getMonth())
          )
        ),
      });

      incident.affected_products.forEach((affected_product) => {
        enrichedDataResponse.push({
          ...incident,
          id: incident.number + affected_product.title,
          name: "> " + affected_product.title,
          type: "task",
          project: incident.number,
          displayOrder: displayCount,
          start: new Date(incident.begin),
          end: incident.end !== undefined ? new Date(incident.end) : new Date(),
          color: severityToColor(incident.severity),
          begin:
            new Date(incident.begin).toLocaleDateString() +
            " " +
            new Date(incident.begin).toLocaleTimeString(),
          end_string:
            incident.end !== undefined
              ? new Date(incident.end).toLocaleDateString() +
                " " +
                new Date(incident.end).toLocaleTimeString()
              : "ongoing",

          duration,
          create_lag: Math.round(
            (new Date(incident.created).getTime() -
              new Date(incident.begin).getTime()) /
              (1000 * 60)
          ),
          impacted_regions: incident.currently_affected_locations.concat(
            incident.previously_affected_locations.filter(
              (item) => incident.currently_affected_locations.indexOf(item) < 0
            )
          ),
          impacted_services: incident.affected_products.length,

          date_year: new Date(incident.begin).getFullYear(),
          date_month: new Date(incident.begin).getMonth(),
          date_day: new Date(incident.begin).getDay(),
          date_hour: new Date(incident.begin).getHours(),
          date_minutes: new Date(incident.begin).getMinutes(),
          date: new Date(incident.begin).toLocaleDateString("en-us"),

          uptime_percentage: downTimePerMonthPercentage(
            duration,
            String(
              String(new Date(incident.begin).getFullYear()) +
                String(new Date(incident.begin).getMonth())
            )
          ),
        });
      });
    } else {
      enrichedDataResponse.push({
        ...incident,
        id: incident.number,
        name: incident.service_name,
        start: new Date(incident.begin),
        end: incident.end !== undefined ? new Date(incident.end) : new Date(),
        color: severityToColor(incident.severity),
        begin:
          new Date(incident.begin).toLocaleDateString() +
          " " +
          new Date(incident.begin).toLocaleTimeString(),
        end_string:
          incident.end !== undefined
            ? new Date(incident.end).toLocaleDateString() +
              " " +
              new Date(incident.end).toLocaleTimeString()
            : "ongoing",

        duration,
        create_lag: Math.round(
          (new Date(incident.created).getTime() -
            new Date(incident.begin).getTime()) /
            (1000 * 60)
        ),
        impacted_regions: incident.currently_affected_locations.concat(
          incident.previously_affected_locations.filter(
            (item) => incident.currently_affected_locations.indexOf(item) < 0
          )
        ),
        impacted_services: incident.affected_products.length,

        date_year: new Date(incident.begin).getFullYear(),
        date_month: new Date(incident.begin).getMonth(),
        date_day: new Date(incident.begin).getDay(),
        date_hour: new Date(incident.begin).getHours(),
        date_minutes: new Date(incident.begin).getMinutes(),
        date: new Date(incident.begin).toLocaleDateString("en-us"),

        uptime_percentage: downTimePerMonthPercentage(
          duration,
          String(
            String(new Date(incident.begin).getFullYear()) +
              String(new Date(incident.begin).getMonth())
          )
        ),
      });
    }
  });
  console.log("new gantt: ", JSON.stringify(enrichedDataResponse.slice(0, 8)));
  return enrichedDataResponse;
}
export function enrichIncidentSummaryData(rawIncidentData) {
  // return newGanttMapping(rawIncidentData);
  const response = rawIncidentData.map((incident) => {
    const duration =
      incident.end !== undefined
        ? Math.round(
            (new Date(incident.end).getTime() -
              new Date(incident.begin).getTime()) /
              (1000 * 60)
          )
        : Math.round(
            (new Date().getTime() - new Date(incident.begin).getTime()) /
              (1000 * 60)
          );
    return {
      ...incident,
      id: incident.number,
      name: incident.service_name,
      start: new Date(incident.begin),
      end: incident.end !== undefined ? new Date(incident.end) : new Date(),
      color: severityToColor(incident.severity),
      begin:
        new Date(incident.begin).toLocaleDateString() +
        " " +
        new Date(incident.begin).toLocaleTimeString(),
      end_string:
        incident.end !== undefined
          ? new Date(incident.end).toLocaleDateString() +
            " " +
            new Date(incident.end).toLocaleTimeString()
          : "ongoing",

      duration,
      create_lag: Math.round(
        (new Date(incident.created).getTime() -
          new Date(incident.begin).getTime()) /
          (1000 * 60)
      ),
      impacted_regions: incident.currently_affected_locations.concat(
        incident.previously_affected_locations.filter(
          (item) => incident.currently_affected_locations.indexOf(item) < 0
        )
      ),
      impacted_services: incident.affected_products.length,

      date_year: new Date(incident.begin).getFullYear(),
      date_month: new Date(incident.begin).getMonth() + 1,
      date_day: new Date(incident.begin).getDay(),
      date_hour: new Date(incident.begin).getHours(),
      date_minutes: new Date(incident.begin).getMinutes(),
      date: new Date(incident.begin).toLocaleDateString("en-us"),

      uptime_percentage: downTimePerMonthPercentage(
        duration,
        dateToInvoiceMonth(new Date(incident.begin))
      ),
    };
  });

  return response;
}
